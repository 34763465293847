import {
  CapacitorBarcodeScanner,
  CapacitorBarcodeScannerTypeHint,
} from "@capacitor/barcode-scanner";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { Sentry } from "../utils/sentry";

const ALLOWED_HOSTNAMES = [
  "use.test.brainchimps.com",
  "use.staging.brainchimps.com",
  "app.usegroup.eu",
];

export function useQRCodeScanner() {
  const navigate = useNavigate();
  const toast = useToast();

  const showInvalidQRCodeError = (qrValue: string, errorDetails?: unknown) => {
    Sentry.captureEvent({
      message: "Invalid QR code scanned",
      level: "warning",
      extra: {
        qrValue,
        errorDetails,
      },
    });

    toast({
      title: "Ungültiger QR-Code",
      description:
        "Dieser QR-Code scheint nicht für unsere App gültig zu sein.",
      status: "error",
      isClosable: true,
    });
  };

  const scanQRCode = async () => {
    const result = await CapacitorBarcodeScanner.scanBarcode({
      hint: CapacitorBarcodeScannerTypeHint.QR_CODE,
    });

    try {
      const url = new URL(result.ScanResult);

      if (!ALLOWED_HOSTNAMES.includes(url.hostname)) {
        showInvalidQRCodeError(result.ScanResult);
        return;
      }

      void navigate(url.pathname);
    } catch (error) {
      showInvalidQRCodeError(result.ScanResult, error);
    }
  };

  return { scanQRCode };
}

import { useState } from "react";
import { toast } from "react-toastify";
import { DeviceAuthenticationData } from "use-smart-locks-shared";
import { bluetoothIlockitService } from "./bluetooth-ilockit.service";

type UseLockControlReturn = {
  isLoading: boolean;
  updateDeviceAuthenticationData: (
    deviceName: string,
    authenticationData: DeviceAuthenticationData,
  ) => void;
  openLock: (lockName: string) => Promise<void>;
  closeLock: (lockName: string) => Promise<void>;
};

export const useLockControl = (): UseLockControlReturn => {
  const [isLoading, setIsLoading] = useState(false);

  const handleBluetoothError = (error: unknown, fallbackMessage: string) => {
    toast.error(error instanceof Error ? error.message : fallbackMessage);
  };

  const updateDeviceAuthenticationData = (
    deviceName: string,
    authenticationData: DeviceAuthenticationData,
  ) => {
    bluetoothIlockitService.updateDeviceAuthenticationData(
      deviceName,
      authenticationData,
    );
  };

  const openLock = async (lockName: string) => {
    setIsLoading(true);
    try {
      await bluetoothIlockitService.openLock(lockName);
    } catch (error) {
      handleBluetoothError(error, "Schloss konnte nicht geöffnet werden");
    } finally {
      setIsLoading(false);
    }
  };

  const closeLock = async (lockName: string) => {
    setIsLoading(true);
    try {
      await bluetoothIlockitService.closeLock(lockName);
    } catch (error) {
      handleBluetoothError(error, "Schloss konnte nicht geschlossen werden");
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    updateDeviceAuthenticationData,
    openLock,
    closeLock,
  };
};

import { Flex, Heading, Text } from "@chakra-ui/react";
import { Button } from "@radix-ui/themes";
import useAppLogo from "./assets/use-app-icon.png";
import { useQRCodeScanner } from "./shared/hooks/useQRCodeScanner";

function AppPage() {
  const { scanQRCode } = useQRCodeScanner();

  return (
    <Flex gap="112px" direction="column" align="center">
      <Flex gap="32px" direction="column" align="center">
        <div>
          <img src={useAppLogo} className="shared-logo" alt="Use logo" />
        </div>
        <Heading as="h1">use Group</Heading>
        <Text fontSize="large" color="gray.600" maxW="300px" my="4">
          Willkommen!
          <br />
          Scannen Sie den QR-Code des Fahrrads.
        </Text>
        <Button onClick={() => void scanQRCode()} size="4">
          QR-Code scannen
        </Button>
      </Flex>
    </Flex>
  );
}

export default AppPage;

import { App } from '@capacitor/app';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export function useDeepLinking() {
  const navigate = useNavigate();

  useEffect(() => {
    const handleDeepLink = ({ url }: { url: string }) => {
      const urlObj = new URL(url);
      void navigate(urlObj.pathname + urlObj.search);
    };

    let listener: { remove: () => Promise<void> } | undefined;

    const setupDeepLinks = async () => {
      try {
        listener = await App.addListener('appUrlOpen', handleDeepLink);
      } catch (error: unknown) {
        console.error('Failed to add deep link listener:', error);
      }
    };

    void setupDeepLinks();

    return () => {
      // Cleanup listener when component unmounts
      void listener?.remove().catch((error: unknown) => {
        console.error('Failed to remove deep link listener:', error);
      });
    };
  }, [navigate]);
}
